let tag = {
  name: '',
  description: '',
  externalDocs: {
    description: '',
    url: ''
  }
}; // current tag in ui;
let tags = [
  {
    name: 'pet',
    description: 'Everything about your Pets',
    externalDocs: {
      description: 'Find out more',
      url: 'http://acmetutor.com/docs/pet'
    }
  },
  {
    name: 'store',
    description: 'Access to Petstore orders'
  },
  {
    name: 'user',
    description: 'Operations about user',
    externalDocs: {
      description: 'Find out more about our users',
      url: 'http://acmetutor.com/docs/user'
    }
  }
];

export {tag};
export {tags};
