import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { JsonEditorComponent, JsonEditorOptions } from 'ang-jsoneditor';

import _ from 'lodash';
import __ from 'underscore';
import pathTemplate from './pathTemplate';
import paths from './paths';
import { tag } from './tags';
import { tags } from './tags';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css']
})
export class MetaComponent implements OnInit, AfterViewInit {
  objectKeys = Object.keys;
  paths = {};
  pathkey = ''; // because Swagger uses the path value as a key
  pathmethod = 'get';
  path = {
    tags: [],
    summary: '',
    description: '',
    operationId: '',
    consumes: [],
    produces: [],
    parameters: [],
    responses: {},
    security: []
  };
  pathTemplate = pathTemplate;
  inputPathData;
  docbaseurl = '';

  tag = tag;
  tags = tags;

  locmap = {
    F: 'formData',
    B: 'body',
    P: 'path',
    Q: 'query',
    H: 'header',
    C: 'cookie'
  };
  schemamap = {
    B: { type: 'boolean' },
    I: { type: 'integer' },
    D: { type: 'decimal' },
    F: { type: 'float' },
    S: { type: 'string' },
    DT: { type: 'string', format: 'date' },
    T: { type: 'string', format: 'time' },
    E: { type: 'string', enum: [], default: '' }
  };

  myswag = {
    swagger: '2.0',
    info: {
      description: '',
      version: '1.0.0',
      title: '',
      termsOfService: '',
      contact: {
        email: 'scientificchess@gmail.com'
      },
      license: {
        name: 'Apache 2.0',
        url: 'http://www.apache.org/licenses/LICENSE-2.0.html'
      }
    },
    host: '',
    basePath: '/v1',
    tags: this.tags,
    schemes: ['https', 'http'],
    paths: this.paths
  };
  editorOptions;

  @ViewChild('choosetag', { static: false }) tagselect;
  @ViewChild('choosepath', { static: false }) pathselect;
  @ViewChild('metaform', { static: false }) metaform;
  @ViewChild(JsonEditorComponent, { static: true }) editor: JsonEditorComponent;

  constructor() {
    this.editorOptions = new JsonEditorOptions();
    this.editorOptions.modes = ['code', 'text', 'tree', 'view']; // set all allowed modes
  }

  ngOnInit() {}

  ngAfterViewInit() {
    // document.getElementById('preloader').classList.add('hide');
    // $('select').selectpicker();
  }

  onCreate(m) {
    console.log(this.metaform);
    console.log(this.metaform.value);
  }

  tagChange() {
    const itag = this.tagselect.nativeElement.value;
    if (itag === 'newtag') {
      this.tag = {
        name: '',
        description: '',
        externalDocs: {
          description: '',
          url: this.docbaseurl
        }
      };
    } else {
      this.tag = __.findWhere(this.tags, { name: itag });
    }
  }

  pathChange() {
    this.pathkey = this.pathselect.nativeElement.value;
    if (this.pathkey === 'newpath') {
      this.path = {
        tags: [],
        summary: '',
        description: '',
        operationId: '',
        consumes: [],
        produces: [],
        parameters: [],
        responses: {},
        security: []
      };
      this.pathkey = '';
      this.pathmethod = 'get';
    } else {
      this.path = this.paths[this.pathkey][this.pathmethod];
    }
  }

  restToSwaggerPath(restpath) {
    return restpath
      .split('/')
      .map(x => {
        if (x.substring(0, 1) === ':') {
          x = '{' + x.substring(1, x.length) + '}';
        }
        return x;
      })
      .join('/');
  }

  swaggerToRestPath(swagpath) {
    return swagpath
      .split('/')
      .map(x => {
        if (x.substring(0, 1) === '{') {
          x = ':' + x.substring(1, x.length - 1);
        }
        return x;
      })
      .join('/');
  }

  addTag() {
    // push a deep copy clone, not a reference to tag
    this.tags.push(_.cloneDeep(this.tag));
  }

  remTag() {
    // remove the tag from tags with the current tag name
    this.tags = __.without(
      this.tags,
      __.findWhere(this.tags, {
        name: this.tag.name
      })
    );
    this.myswag.tags = this.tags;
  }

  addPath() {
    const path = this.paths[this.restToSwaggerPath(this.pathkey)]
      ? this.paths[this.restToSwaggerPath(this.pathkey)]
      : {};
    path[this.pathmethod] = _.cloneDeep(this.path);
    this.paths[this.restToSwaggerPath(this.pathkey)] = path;
  }

  remPath() {
    // don't forget to change :var to {var}
    const dropkey = this.swaggerToRestPath(this.pathkey);
    delete this.paths[dropkey][this.pathmethod];
    this.myswag.paths = this.paths;
  }

  updateInput(value, field) {
    console.log(value);
    console.log(field);
    _.set(this, field, value);
  }

  updateOptions(options, field) {
    const labels = [];
    for (let i = 0, n = options.length; i < n; i++) {
      // looping over the options
      if (options[i].value && options[i].selected) {
        labels.push(options[i].value);
      }
    }
    // value.forEach(element => {
    //   if (element.selected) {
    //     labels.push(element.label);
    //   }
    // });
    _.set(this, field, labels);
  }

  updateInputPathData(value) {
    console.log(value);
    // <div class="help-block"><b>Example:</b> P:I:*userId:id of the user to get</div>

    const inArray = value.split('\n');
    let parameters = [];
    inArray.forEach(element => {
      const elemArray = element.split(':');
      const type = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
      console.log('my type ' + type);
      switch (type) {
        case 'formData':
          parameters = this.makeFormData(elemArray, parameters);
          break;
        case 'body':
          parameters = this.makeBodyData(elemArray, parameters);
          break;
        case 'path':
          parameters = this.makePathData(elemArray, parameters);
          break;
        case 'query':
          parameters = this.makeQueryData(elemArray, parameters);
          break;
        case 'header':
          parameters = this.makeHeaderData(elemArray, parameters);
          break;
        case 'cookie':
          parameters = this.makeCookieData(elemArray, parameters);
          break;
        default:
          parameters = this.makeGenericData(elemArray, parameters);
          break;
      }
      // parameters = this.makeGenericData(elemArray, parameters);
    });
    this.path.parameters = parameters;
  }

  makeGenericData(elemArray, parameters) {
    console.log('making generic data');
    const parameter = {
      in: '',
      name: '',
      description: '',
      required: false,
      schema: {}
    };
    parameter.in = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
    parameter.schema = this.schemamap[elemArray[1]]
      ? this.schemamap[elemArray[1]]
      : {};
    if (elemArray[2][0] && elemArray[2][0] === '*') {
      parameter.required = true;
      elemArray[2] = elemArray[2].substring(1, elemArray[2].length);
    }
    parameter.name = elemArray[2] ? elemArray[2] : '';
    parameter.description = elemArray[3] ? elemArray[3] : '';
    parameters.push(parameter);
    return parameters;
  }

  makeFormData(elemArray, parameters) {
    console.log('making form data');
    let parameter = {
      in: '',
      name: '',
      description: '',
      required: false,
      type: ''
    };
    parameter.in = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
    const s = this.schemamap[elemArray[1]] ? this.schemamap[elemArray[1]] : {};
    parameter = { ...parameter, ...s };
    if (elemArray[2][0] && elemArray[2][0] === '*') {
      parameter.required = true;
      elemArray[2] = elemArray[2].substring(1, elemArray[2].length);
    }
    parameter.name = elemArray[2] ? elemArray[2] : '';
    parameter.description = elemArray[3] ? elemArray[3] : '';
    parameters.push(parameter);
    return parameters;
  }

  makeBodyData(elemArray, parameters) {
    // does body parameter already exist?
    let parameter = __.findWhere(parameters, { in: 'body' });
    if (!parameter) {
      // create a new body param and push it in if it doesn't already exist
      parameter = {
        in: 'body',
        name: 'bodyname',
        description: 'body description; ',
        required: true,
        schema: {
          type: 'object',
          required: [],
          properties: {}
        }
      };

      parameters.push(parameter);
    }

    let propName = elemArray[2] ? elemArray[2] : '';
    const propRequired = elemArray[2][0] ? elemArray[2][0] : '';
    const propDesc = elemArray[3] ? elemArray[3] : '';
    const propDetails = this.schemamap[elemArray[1]]
      ? this.schemamap[elemArray[1]]
      : {};

    if (propRequired === '*') {
      propName = propName.substring(1, propName.length); // remove the leading *
      parameter.schema.required.push(propName);
    }

    parameter.schema.properties[propName] = {
      ...propDetails,
      description: propDesc
    };

    parameter.description += propName + propRequired + ' - ' + propDesc + '; ';

    return parameters;
  }

  makePathData(elemArray, parameters) {
    let parameter = {
      in: '',
      name: '',
      description: '',
      required: true, // path parameters must have required as true
      type: ''
    };
    parameter.in = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
    const s = this.schemamap[elemArray[1]] ? this.schemamap[elemArray[1]] : {};
    parameter = { ...parameter, ...s };
    if (elemArray[2][0] && elemArray[2][0] === '*') {
      parameter.required = true;
      elemArray[2] = elemArray[2].substring(1, elemArray[2].length);
    }
    parameter.name = elemArray[2] ? elemArray[2] : '';
    parameter.description = elemArray[3] ? elemArray[3] : '';
    parameters.push(parameter);
    return parameters;
  }

  makeQueryData(elemArray, parameters) {
    let parameter = {
      in: '',
      name: '',
      description: '',
      required: false, // query parameters can be required or optional
      type: ''
    };
    parameter.in = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
    const s = this.schemamap[elemArray[1]] ? this.schemamap[elemArray[1]] : {};
    parameter = { ...parameter, ...s };
    if (elemArray[2][0] && elemArray[2][0] === '*') {
      parameter.required = true;
      elemArray[2] = elemArray[2].substring(1, elemArray[2].length);
    }
    parameter.name = elemArray[2] ? elemArray[2] : '';
    parameter.description = elemArray[3] ? elemArray[3] : '';
    parameters.push(parameter);
    return parameters;
  }

  makeHeaderData(elemArray, parameters) {
    let parameter = {
      in: '',
      name: '',
      description: '',
      required: false, // header parameters can be required or optional
      type: ''
    };
    parameter.in = this.locmap[elemArray[0]] ? this.locmap[elemArray[0]] : '';
    const s = this.schemamap[elemArray[1]] ? this.schemamap[elemArray[1]] : {};
    parameter = { ...parameter, ...s };
    if (elemArray[2][0] && elemArray[2][0] === '*') {
      parameter.required = true;
      elemArray[2] = elemArray[2].substring(1, elemArray[2].length);
    }
    parameter.name = elemArray[2] ? elemArray[2] : '';
    parameter.description = elemArray[3] ? elemArray[3] : '';
    parameters.push(parameter);
    return parameters;
  }

  makeCookieData(elemArray, parameters) {
    return this.makeGenericData(elemArray, parameters);
  }
}
