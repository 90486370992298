import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { MetaComponent } from './components/meta/meta.component';
import { NgJsonEditorModule } from 'ang-jsoneditor'
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [
    AppComponent,
    MetaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    NgJsonEditorModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
